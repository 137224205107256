import React from 'react';

/**
 * Images/SVG
 */
import Background from '../images/appDevH.jpg';
import AppDevOne from '../images/appDev.jpg';
import AppDevTwo from '../images/appDev2.jpg';
import AppDevThree from '../images/appDev3.jpg';

/**
 * Components
 */
import SEO from '../components/seo/seo';
import Layout from '../components/layout/layout';
import Banner from '../components/default-banner/default-banner';
import DynamicTextBlock from '../components/dynamic-text-block/dynamic-text-block';

export default () => {

  const innerBannerData = {
    backgroundImg: { backgroundImage: `url(${Background}` },
    mainTitle: 'Mobile App Development London',
    mainText: ''
  };

  const dataBlockOne = {
    left: true,
    img: <img alt="animation" src={AppDevOne}/>,
    title: 'Why You Need An App Development London Company For Your Business',
    textPieceOne: `Most people think going digital means getting a website for your business and ensuring it brings traffic. While this is a crucial step, it alone isn't enough. According to our experts, more and more people are moving their businesses towards mobile platforms with the help of an app development London company, due to two significant reasons:`,
    list: [{title: 'Permanent Audience: ', content: `When was the last time you visited a website and left it open in your browser? Probably never! Our experts say this is where websites lack. It doesn't keep on the audiences for longer durations. On the other hand, mobile applications stay on a person's smartphone. Therefore, it has a higher chance of bringing business than the website alone.`}, {title: 'Trust: ', content: `When almost every business owns a website, it is difficult for people to trust just anyone. But having an application shows that your business is much advanced and credible. That is why your audience is able to trust you more than the competitors who don't own any app.`}],
    listAfterLine: '',
    textPieceTwo: 'Thus, you need to go for a professional app development London company to see your business grow. This is where Radium can help you out.'
  };

  const dataBlockTwo = {
    left: false,
    img: <img alt="animation" src={AppDevTwo}/>,
    title: 'What Do We do?',
    textPieceOne: `Whether you are a recently set startup or a full-fledged business, we can provide you with the most desirable mobile app development solution. Our team of experts begins by designing a strategy according to your business's individual needs. They ensure to incorporate all the best-in-class features that your competitors may or may not be offering.`,
    list: [],
    listAfterLine: '',
    textPieceTwo: `Along with that, our professional app development London team keeps an eye on the unnecessary features that can make your application's design more complicated. Therefore, keeping things simple for the users and creating an incomparable user experience.`
  };

  const dataBlockThree = {
    left: true,
    img: <img alt="animation" src={AppDevThree}/>,
    title: 'What Sets Us Apart?',
    textPieceOne: `The very first factor that makes us different from other app developers is our approach to designing. Our diverse teams possess their individual set of skills that help us provide our clients with the best-in-class solutions for their businesses. Irrespective of your business's domain and level, we can offer you the most appropriate digital solutions.`,
    list: [],
    listAfterLine: '',
    textPieceTwo: `Moreover, we believe in keeping things straightforward for you. That is why we will provide you with results rather than just application development. Our team ensures to analyze the user insights and data to see what works for your business and what doesn't. This way, you don't have to compromise on anything.`
  };

  return (
    <Layout>
      <SEO 
        title="About" 
        keywords={[ `digital`, `agency`, `technology`, `Radium`, `About` ]} 
      />
      <Banner {...innerBannerData}/>
      <DynamicTextBlock {...dataBlockOne}/>
      <DynamicTextBlock {...dataBlockTwo}/>
      <DynamicTextBlock {...dataBlockThree}/>
      <div className="container spacing-bottom">
        <i>All in all, our app development London team is a group of experts that will provide you with the desired</i>
      </div>
    </Layout>
  )
};
